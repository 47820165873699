









































































import { Tabs } from '@/components/ChatsBar/types'
import { Tab } from '@/store/modules/chatsBar/types'
import { throttle } from 'quasar'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {
    Badge: () => import('@/components/TeamsBar/Badge.vue'),
    IconBellOff: () => import('@/components/UI/icons/IconBellOff.vue'),
  },
})
export default class FilterRow extends Vue {
  @Prop({ type: String }) private readonly tab!: Tab
  @Prop({ type: Array }) private readonly tabs!: Tabs
  @Prop({ type: Boolean }) private readonly disable?: boolean

  private max = 0
  private showLeftBgHelper = false
  private showRightBgHelper = false
  private resizeObserverEnabled = false

  mounted () {
    this.scroller.addEventListener('scroll', this.recalculate)
    this.scroller.addEventListener('wheel', this.onWheel)
    this.resizeObserverEnabled = true
    this.setTabToCenter(this.tab)

    this.$el.addEventListener('mousedown', e => {
      const el = e.target as Element
      if (!el || !el.classList.contains('q-tabs__arrow')) return

      if (el.classList.contains('q-tabs__arrow--left')) {
        window.goal('chatsBar', { chatsBar: 'Клик «Влево»' })
      } else if (el.classList.contains('q-tabs__arrow--right')) {
        window.goal('chatsBar', { chatsBar: 'Клик «Вправо»' })
      }
    })
  }

  beforeDestroy () {
    this.scroller.removeEventListener('scroll', this.recalculate)
    this.scroller.removeEventListener('wheel', this.onWheel)
  }

  private get scroller () {
    return this.$el.getElementsByClassName('q-tabs__content')[0] as HTMLElement
  }

  private recalculate () {
    if (!this.scroller) return

    const { clientWidth, scrollLeft, scrollWidth } = this.scroller

    this.max = scrollWidth - clientWidth
    this.showLeftBgHelper = this.max > 0 && scrollLeft !== 0
    this.showRightBgHelper = this.max > 0 && scrollLeft !== this.max
  }

  private onWheel (e: WheelEvent) {
    this.scrollGoal()

    const { deltaX, deltaY } = e

    if (Math.abs(deltaX) > Math.abs(deltaY)) return

    e.preventDefault()

    const left = this.scroller.scrollLeft + deltaY
    this.scroller.scrollTo({ left })
  }

  @Watch('tab')
  async setTabToCenter (tab: Tab) {
    await this.$nextTick()

    const index = this.tabs.findIndex(item => item.name === tab)
    const element = this.scroller.getElementsByClassName('q-tab')[index]
    const elRect = element.getBoundingClientRect()
    const containerRect = this.scroller.getBoundingClientRect()

    const elCenter = elRect.left + (elRect.width / 2)
    const containerCenter = containerRect.left + (containerRect.width / 2)
    const left = this.scroller.scrollLeft - (containerCenter - elCenter)

    this.scroller.scrollTo({ left, behavior: 'smooth' })
  }

  private scrollGoal = throttle(() => {
    window.goal('chatsBar', { chatsBar: 'Скрол мышкой на панели табов' })
  }, 2000)

  private onTabClick (tab: Tab) {
    window.goal('chatsBar', { chatsBar: this.getGoalMsg(tab) })
  }

  private getGoalMsg (tab: Tab) {
    switch (tab) {
      case 'all': return 'Клик «Все»'
      case 'direct': return 'Клик «Личные»'
      case 'group': return 'Клик «Групповые»'
      case 'hidden': return 'Клик «Архив»'
      case 'unreadOnly': return 'Клик «Непрочитанные»'
      case 'comments': return 'Клик «Комментарии»'
    }
  }
}
