








































import type MenuType from '@/components/ChatsBar/Header/ControlRow/Menu.vue'
import { IconMore } from '@tada/icons'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconMore,
    Menu: () => import('@/components/ChatsBar/Header/ControlRow/Menu.vue'),
  },
})
export default class ControlRow extends Vue {
  @Prop({ type: Boolean }) private readonly shortView!: boolean
  @Prop({ type: Boolean }) private readonly sortByName!: boolean
  @Prop({ type: Boolean }) private readonly groupBadges!: boolean
  @Prop({ type: Boolean }) private readonly groupNotifications!: boolean
  @Prop({ type: Boolean }) private readonly showShortViewOption?: boolean
  @Prop({ type: Boolean }) private readonly isNotificationsDisabled!: boolean
  @Prop({ type: Boolean })
  private readonly showGroupNotificationsOption?: boolean

  @Ref() menu!: MenuType

  private isMenuImported = false

  private async showMenu () {
    /**
     * Will be shown automatically for the first time,
     * so let's just update the flag 💩
     */
    if (!this.isMenuImported) this.isMenuImported = true
    else this.menu.show()
  }
}
