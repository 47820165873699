




























































import type MenuType from '@/components/ChatsBar/Header/SearchRow/Menu.vue'
import { IconEdit } from '@tada/icons'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconEdit,
    IconX: () => import('@tada/icons/dist/IconX.vue'),
    Menu: () => import('@/components/ChatsBar/Header/SearchRow/Menu.vue'),
  },
})
export default class SearchRow extends Vue {
  @Prop({ type: String }) private readonly search!: string
  @Prop({ type: Boolean }) private readonly showImportOption?: boolean

  @Ref() menu!: MenuType

  private isMenuImported = false

  private async showMenu () {
    window.goal('chatsBar', { chatsBar: 'Клик на кнопке «Создать»' })
    /**
     * Will be shown automatically for the first time,
     * so let's just update the flag 💩
     */
    if (!this.isMenuImported) this.isMenuImported = true
    else this.menu.show()
  }

  private onSearchClick () {
    window.goal('chatsBar', { chatsBar: 'Клик на «Поиск чата»' })
  }
}
