








































import ControlRow from '@/components/ChatsBar/Header/ControlRow/index.vue'
import FilterRow from '@/components/ChatsBar/Header/FilterRow/index.vue'
import SearchRow from '@/components/ChatsBar/Header/SearchRow/index.vue'
import { Tabs } from '@/components/ChatsBar/types'
import { Tab } from '@/store/modules/chatsBar/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ControlRow,
    FilterRow,
    SearchRow,
  },
})
export default class Header extends Vue {
  @Prop({ type: String }) private readonly tab?: Tab
  @Prop({ type: Array }) private readonly tabs?: Tabs
  @Prop({ type: String }) private readonly search?: string
  @Prop({ type: Boolean }) private readonly disableTabs?: boolean
  @Prop({ type: Boolean }) private readonly shortView?: boolean
  @Prop({ type: Boolean }) private readonly sortByName?: boolean
  @Prop({ type: Boolean }) private readonly groupBadges?: boolean
  @Prop({ type: Boolean }) private readonly groupNotifications?: boolean
  @Prop({ type: Boolean }) private readonly showShortViewOption?: boolean
  @Prop({ type: Boolean }) private readonly isNotificationsDisabled!: boolean
  @Prop({ type: Boolean })
  private readonly showGroupNotificationsOption?: boolean

  @Prop({ type: Boolean }) private readonly showImportOption?: boolean
}
