



























































import Header from '@/components/ChatsBar/Header/index.vue'
import type List from '@/components/ChatsBar/List/index.vue'
import { Tabs } from '@/components/ChatsBar/types'
import * as EntitySelection from '@/components/Modals/Default/EntitySelector/Presets'
import {
  chatImportStore,
  chatsBarStore,
  contactsStore,
  rootStore,
  teamsStore,
  uiSettingsStore,
  uiStore,
} from '@/store'

import { SortType, Tab, Unread } from '@/store/modules/chatsBar/types'
import { getDefaultFuseOptions } from '@/utils'
import { multiSearch } from '@/utils/Common'
import { Chat, Contact, JID } from '@tada-team/tdproto-ts'
import Fuse from 'fuse.js'
import { debounce } from 'quasar'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component({
  components: {
    Header,
    List: () => import('@/components/ChatsBar/List/index.vue'),
    Placeholder: () => import('@/components/ChatsBar/Placeholder.vue'),
  },
})
export default class ChatsBar extends Vue {
  @Ref() list?: List

  @Getter private readonly profile!: Contact

  private shortView = false
  private sortType: SortType = 'activity'
  private groupBadges = false
  private groupNotifications = false
  private selectedChat = ''

  private unread: Unread = { direct: 0, group: 0, thread: 0 }

  private get tabs (): Tabs {
    return [
      {
        name: 'comments',
        label: this.$t('chatsBar.filter.comments').toString(),
        unread: this.unread.thread,
      },
      {
        name: 'all',
        label: this.$t('chatsBar.filter.all').toString(),
        unread: this.unread.direct + this.unread.group,
      },
      {
        name: 'direct',
        label: this.$t('chatsBar.filter.direct').toString(),
        unread: this.unread.direct,
      },
      {
        name: 'group',
        label: this.$t('chatsBar.filter.group').toString(),
        isMuted: !this.groupNotifications,
        unread: this.unread.group,
      },
      {
        name: 'unreadOnly',
        label: this.$t('chatsBar.filter.unreadOnly').toString(),
        unread: this.unread.direct + this.unread.group,
      },
      {
        name: 'hidden',
        label: this.$t('chatsBar.filter.hidden').toString(),
      },
    ]
  }

  private get team () {
    return teamsStore.getters.currentTeam
  }

  private get teamId () {
    return this.team.uid
  }

  private get teamUnread (): Unread {
    const { direct, group, thread } = this.team.unread
    return { direct: direct.chats, group: group.chats, thread: thread.chats }
  }

  private get tab () {
    return chatsBarStore.state.tab
  }

  private get fuse () {
    const existsJids = chatsBarStore.state.chats.map(item => item.jid)
    const contacts = contactsStore.getters.contactList
      .filter(item => !item.isArchive && !existsJids.includes(item.jid))
      .map(item => Chat.fromJSON({
        can_send_message: item.canSendMessage,
        chat_type: 'direct',
        created: '',
        display_name: item.displayName,
        gentime: 0,
        icons: item.icons.toJSON(),
        jid: item.jid,
        last_activity: item.lastActivity,
      }))

    // TODO: нужен ли здесь , ...chatsBarStore.state.threadChats?
    const data = [...contacts, ...chatsBarStore.state.chats]
    return new Fuse(data, getDefaultFuseOptions(['displayName']))
  }

  private get chats () {
    const q = this.search.trim()
    if (q.length > 1) {
      return multiSearch((s: string) => this.fuse.search(s), q, 'jid')
    }

    return chatsBarStore.getters.currentTabChats
  }

  private get isLoading () {
    return chatsBarStore.state.isLoading
  }

  private get showPlaceholder () {
    return chatsBarStore.state.isLoaded && !chatsBarStore.getters.currentTabChats.length
  }

  private get isUnreadOnlyDone () {
    return Boolean(
      !chatsBarStore.getters.unreadOnlyChats.length && (
        !chatsBarStore.state.isLoaded || chatsBarStore.getters.allChats.length
      ),
    )
  }

  private get showShortViewOption () {
    const { changeableFields } = this.profile
    return (
      changeableFields?.includes('group_short_view') &&
      changeableFields?.includes('contact_short_view')
    )
  }

  private get showGroupNotificationsOption () {
    const { changeableFields } = this.profile
    return changeableFields?.includes('group_notifications_enabled')
  }

  private get showImportOption () {
    return teamsStore.getters.currentTeam.me.canImportChats
  }

  private get shortViewFromStore () {
    const { contactShortView, groupShortView } = this.profile
    return Boolean(contactShortView && groupShortView)
  }

  private get sortTypeFromStore () {
    return chatsBarStore.state.sortType
  }

  private get search () {
    return chatsBarStore.state.search
  }

  private get groupNotificationsFromStore () {
    return this.profile.groupNotificationsEnabled ?? false
  }

  private get uiSettingsSortType (): SortType {
    return uiSettingsStore.getters.sortingChatsType(true) ? 'name' : 'activity'
  }

  private get isNotificationsDisabled (): boolean {
    return !rootStore.state.isNotificationsAllowed
  }

  mounted () {
    chatsBarStore.actions.load(this.teamId)
    chatsBarStore.actions.loadThreads()
  }

  @Watch('uiSettingsSortType', { immediate: true })
  private onUiSettingsSortTypeUpdated (value: SortType) {
    chatsBarStore.mutations.setSortType(value)
  }

  @Watch('shortViewFromStore', { immediate: true })
  private updateShortView (value: boolean) {
    this.shortView = value
  }

  @Watch('sortTypeFromStore', { immediate: true })
  private updateSortType (value: SortType) {
    this.sortType = value
  }

  @Watch('groupNotificationsFromStore', { immediate: true })
  private updateGroupNotifications (value: boolean) {
    this.groupNotifications = value
  }

  private onChangeShortView (value: boolean) {
    window.goal('chatsBar', { chatsBar: 'Клик «Компактынй вид»' })
    this.updateShortView(value)
    chatsBarStore.actions.changeShortView(value)
  }

  private onChangeSortType (byName: boolean) {
    window.goal('chatsBar', { chatsBar: 'Клик «По алфавиту»' })
    const type: SortType = byName ? 'name' : 'activity'
    this.updateSortType(type)
    chatsBarStore.mutations.setSortType(type)
    uiSettingsStore.actions.setChatSortingType({ direct: true, value: +byName })
  }

  private async onChangeGroupNotifications (value: boolean) {
    window.goal('chatsBar', { chatsBar: 'Клик «Уведомления групповых чатов»' })
    this.updateGroupNotifications(value)
    try {
      await contactsStore.actions.editContact({
        jid: this.profile.jid,
        params: { group_notifications_enabled: value },
      })
    } catch (e) {
      // TODO: refactor this
      setTimeout(() => {
        this.updateGroupNotifications(this.groupNotificationsFromStore)
      }, 300)
      throw e
    }
  }

  private onChangeSearch (value: string) {
    this.selectedChat = ''
    chatsBarStore.mutations.setSearch(value)
    if (this.list) this.list.resetScroll()
  }

  @Watch('teamId')
  private onTeamIdUpdated () {
    chatsBarStore.mutations.reset()
    this.onTabUpdated('all')
    chatsBarStore.actions.load(this.teamId)
    chatsBarStore.actions.loadThreads()
  }

  @Watch('teamUnread')
  private onTeamUnreadUpdated () {
    const { direct: directNew, group: groupNew, thread: threadNew } = this.teamUnread
    const { direct, group, thread } = this.unread

    if (direct === directNew && group === groupNew && thread === threadNew) return
    if (direct === 0 || group === 0 || thread === 0) return this.updateUnreadDebounce()

    this.updateUnread()
  }

  private updateUnread () {
    this.unread = this.teamUnread
  }

  private updateUnreadDebounce = debounce(() => {
    this.updateUnread()
  }, 500)

  private onTabUpdated (tab: Tab) {
    chatsBarStore.mutations.setTab(tab)
    if (this.list) this.list.resetScroll()
  }

  private onCreateDirectChatClick () {
    window.goal('chatsBar', { chatsBar: 'Клик создать «Личный чат»' })
    EntitySelection.openSelectionOfTeamMembers()
  }

  private onCreateGroupChatClick () {
    window.goal('chatsBar', { chatsBar: 'Клик создать «Групповой чат»' })
    uiStore.actions.showModal({
      instance: 'GroupForm',
      payload: { type: 'create' },
    })
  }

  private onImportClick () {
    window.goal('chatsBar', { chatsBar: 'Клик создать «Импорт из Telegram»' })
    chatImportStore.actions.openChatImportDialog()
  }

  private onShowPublicGroupsClick () {
    window.goal('chatsBar', { chatsBar: 'Клик создать «Присоединиться»' })
    uiStore.actions.showModal({ instance: 'GroupJoin' })
  }

  private onSearchDownClick () {
    this.moveChatSelect('next')
  }

  private onSearchUpClick () {
    this.moveChatSelect('prev')
  }

  private async onSearchEnterClick () {
    if (!this.selectedChat) return
    await this.goToChat(this.selectedChat)
  }

  private onSearchBlur () {
    this.selectedChat = ''
  }

  private async onListItemClick (event: MouseEvent, id: JID) {
    if (event.ctrlKey || event.shiftKey || event.metaKey) return
    event.preventDefault()

    await this.goToChat(id)
  }

  private moveChatSelect (dir: 'next' | 'prev') {
    const chats = this.chats
    if (!chats.length) return

    const startIndex = (dir === 'prev') ? (chats.length - 1) : 0

    const chatId = this.selectedChat
    if (!chatId) {
      this.selectedChat = chats[startIndex].jid
      return
    }

    const index = chats.findIndex(chat => chat.jid === chatId)
    if (index === -1) {
      this.selectedChat = chats[startIndex].jid
      return
    }

    const chat = chats[index + ((dir === 'prev') ? -1 : 1)]
    if (!chat) {
      this.selectedChat = chats[startIndex].jid
      return
    }

    this.selectedChat = chat.jid
  }

  private async goToChat (jid: string) {
    await this.$router.push({
      name: 'Chat',
      params: { jid: jid, teamId: this.teamId },
    })
  }
}
